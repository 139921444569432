@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Slick Carousel CSS */

/* Container for Horizontal Slider */
.horizontal-slider {
  max-width: 100%;
  background-color: #FFFFFF;
}

/* Individual Slide Styling */
.horizontal-slider .slick-slide {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

/* Slide Icon Styling */
.horizontal-slider .slide-icon {
  font-size: 2.5rem;
  color: #075DD1; /* Icon color */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Slide Text Styling */
.horizontal-slider .slide-name {
  font-weight: bold;
  color: #040925;
  font-size: 0.9rem;
}

/* Custom Navigation Buttons */
.horizontal-slider .slick-prev,
.horizontal-slider .slick-next {
  color: #075DD1;
  z-index: 1;
}

